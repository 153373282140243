import type { MemoInterface } from "@carglassgithub/memod-sdk";
import { File } from "@kanvas/client-js";
import { useMemo } from "react";

export function useMemoCover(files: MemoInterface["files"]) {
  const coverImage = useMemo(() => {
    const index = files.find(
      // find file for cover image
      (file) => file.field_name === "cover-image"
    );

    return index;
  }, [files])!;

  return {
    cover: coverImage,
  };
}

export function useCollectionCover(files: MemoInterface["files"]) {
  const coverImage = useMemo(() => {
    const index = files.find(
      // find file for cover image
      (file: any) => file.field_name === "cover_image"
    );

    return index;
  }, [files])!;
  
  return {
    cover: coverImage,
  };
}

export function useMemoCoverShare(files: MemoInterface["files"]) {
  const coverImage = useMemo(() => {
    const list = files.reduce((items, file) => {
      ["sharable-cover-image", "shareable-cover-image"].includes(file.field_name as string) && items.splice(0, 0, file);
      ["cover-image", "cover_image"].includes(file.field_name as string) && items.push(file);
      return items;
    }, [] as File[]);

    return list?.[0] || {};
  }, [files])!;

  return {
    cover: coverImage,
  };
}
