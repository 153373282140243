import dynamic from "next/dynamic";
import React from "react";

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const partRegx = /^part-\d+$/;

export const validPart = (part: string) => partRegx.test(part);

export const sanitizePath = (path: string) =>
  path.split("/").filter((chunk) => chunk.trim() !== "");

export const getCurrentPart = (part: string) => {
  return part.match(/^part-(\d+)$/);
};

export const outRange = (part: number, max: number) => {
  return part === 0 || part > max;
};

export const WithNoSSR = <T extends any>(
  Component: React.FunctionComponent<T>
) => dynamic(() => Promise.resolve(Component), { ssr: false });

export const resizeImage = (imageName: string, w = "500") =>
  `https://resizer.memodapp.com/v1/image/${imageName}?w=${w}`;
