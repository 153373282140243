import BaseModal from "@components/atoms/base-modal";
import Button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import classNames from "classnames";
import { useState } from "react";
import { sdkv1 } from "@composer/models/api";
import Cookies from "cookie-universal";
import { MemoInterface } from "@carglassgithub/memod-sdk";
import { MemoSaveAtom, UserAtom, UserSaveItemsAtom } from "@models/state";
import { useAtomValue, useStore } from "jotai";
import useAmplitude from "@models/interactions/use-amplitude";
import { SaveMemoInterface } from "@carglassgithub/memod-sdk";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  setToggleCreateModal: (active: boolean) => void;
  memo?: MemoInterface;
  onSaves?: (saves: number) => void;
  save?: (active: boolean) => void;
  onCreate?: () => void;
}

const cookies = Cookies();

export function useCreateCollectionModal(props: Props) {
  const [input, setInput] = useState<{
    list_name: string;
    description: string;
    is_public: number;
  }>({
    list_name: "",
    description: "",
    is_public: 1,
  });

  const user = useAtomValue(UserAtom);
  const { operations } = useAmplitude();
  const store = useStore();

  const create = async () => {
    try {
      const token = cookies.get("token");

      const { data } = await sdkv1.http.http.post(
        `/users/${user?.id}/lists`,
        {
          list_name: input.list_name,
          is_public: input.is_public,
          description: input.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token || ""}`,
            "content-type": "text/json",
          },
        },
      );

      operations.send("Created Entity", {
        board_id: data.id,
        board_title: input.list_name,
        title: user?.displayname,
        event_name: "create",
        entity_type: "collection",
      });

      if (props.memo) {
        props.setToggleCreateModal(false);

        const response = await sdkv1.http.http.post<SaveMemoInterface>(
          "/feeds/save",
          {
            list_id: data?.id,
            storm_id: props.memo.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token || ""}`,
              "content-type": "text/json",
            },
          },
        );

        props.save?.(response.data.is_saved ? true : false);

        operations.send(
          response.data.is_saved ? "Saved Entity" : "Remove Saved Entity",
          {
            [response.data.is_saved ? "saved_at" : "remove_saved_at"]: Date
              .now(),
            entity_owner_id: props.memo.writer.id,
            entity_id: props.memo.id,
            entity_name: props.memo?.title,
            entity_type: "memo",
          },
        );

        store.set(UserSaveItemsAtom, (current) => {
          const saved = true;
          store.set(MemoSaveAtom, true);
          return [...current];
        });

        const transformedBoards = {
          key: data.id,
          text: data.list_name,
          saved: true,
        };

        store.set(UserSaveItemsAtom, (current) => [
          ...current,
          transformedBoards,
        ]);

        toast("Published! Your Collection is now live on Memo’d", {
          icon: <Icon.NotificationCheck />,
        });

        //@ts-ignore
        props.onSaves?.(response.data.memo_total_saved);
        props.onCreate?.();
        return;
      } else {
        window.location.href =
          `/${data?.user?.displayname}/collections/${data?.slug}-${data?.id}`;
      }
    } catch (e: any) {
      if (e?.errors?.message === "List name already exists") {
        toast("Error: A Collection with this title already exists.", {
          icon: <Icon.ErrorIcon size="30" />,
        });
        return;
      }
      toast(e?.errors?.message, {
        icon: <Icon.ErrorIcon size="30" />,
      });
    }
  };

  return {
    models: {
      input,
      create,
    },
    operations: {
      setInput,
    },
  };
}

export default function CreateCollectionModal(props: Props) {
  const { operations, models } = useCreateCollectionModal(props);

  return (
    <BaseModal open={props.open}>
      <div
        className={classNames(
          "flex h-[512px] w-[343px] flex-col bg-memod-base p-10 sm:w-[488px] md:w-[656px]",
        )}
      >
        <div className="flex w-full flex-col items-center justify-start gap-8">
          <div className="flex w-full justify-between">
            <span className=" text-2xl font-semibold text-base-white">
              {translate("base.create-collection")}
            </span>
            <div className="flex w-1/12 flex-row justify-end">
              <button
                className="justify-self-end"
                onClick={() => props.setToggleCreateModal(false)}
              >
                <Icon.Close className="text-white" />
              </button>
            </div>
          </div>

          <div className="flex h-12 w-full flex-col justify-start gap-2">
            <input
              maxLength={45}
              onChange={(e) =>
                operations.setInput({
                  ...models.input,
                  list_name: e.target.value,
                })}
              placeholder={translate("base.collection-title")}
              className="flex h-10 w-full items-end gap-3 border-b border-base-grey bg-transparent p-0 text-[28px] font-semibold text-[#EBEBF54D] outline-none"
            />
            <span
              className={classNames("hidden ", {
                "text-label !flex w-full justify-end text-xs !text-memod-error-base":
                  models.input.list_name.length >= 45,
              })}
            >
              Max value is 45 characters long.
            </span>
          </div>

          <div className="flex w-full flex-col items-start gap-3 p-0">
            <span className="flex h-[200px] w-full flex-col items-start rounded-xl border border-base-grey p-3 md:w-[576px]">
              <textarea
                cols={30}
                rows={1}
                maxLength={280}
                onChange={(e) =>
                  operations.setInput({
                    ...models.input,
                    description: e.target.value,
                  })}
                placeholder={translate("base.collection-description")}
                className="block h-[200px] w-full gap-3 bg-transparent text-xl text-[#EBEBF54D] outline-none"
              />
            </span>
          </div>

          <Button.Pill
            variant="primary"
            onClick={models.create}
            size="default"
            disabled={models.input.list_name.trim().length === 0}
            className={classNames(
              "w-[99px] self-end bg-base-grey !text-black",
              { "!bg-white": models.input.list_name.trim()! },
            )}
          >
            {translate("base.create")}
          </Button.Pill>
        </div>
      </div>
    </BaseModal>
  );
}
