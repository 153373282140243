import { useMemo } from "react";
import { WriterInterface } from "@carglassgithub/memod-sdk";
import Avatar from "@components/atoms/avatar";
import classNames from "classnames";
import Link from "next/link";
import { resizeImage } from "@models/interactions/utils";

interface Props {
  className?: string;
  linkClassName?: string;
  textClassName?: string;
  writer: WriterInterface;
  size?: "default" | number;
  avatarSize?: number;
  showName?: boolean;
}

export function useUser(props: Props) {
  const { writer, size = "default" } = props;

  const name = useMemo(
    () =>
      `${writer?.firstname?.trim() ?? ""} ${writer?.lastname?.trim() ?? ""}`,
    [writer]
  );

  const username = useMemo(
    () =>
      writer?.displayname.trim().length > 0 ? `@${writer.displayname}` : "",
    [writer]
  );

  const imageSize = useMemo(() => (size === "default" ? 20 : 40), [size]);

  const classname = classNames(
    " gap-2.5 items-start justify-start justify-between",
    props.className,
    {
      "hidden sm:flex": !props.className,
    }
  );

  return {
    model: {
      name,
      username,
      writer,
      size,
      imageSize,
      classname,
      displayname: writer?.displayname,
    },
  };
}

export default function UserInformation(props: Props) {
  const { model } = useUser(props);

  return (
    <div className={model.classname}>
      <Link
        href={`/${model.displayname}/memos`}
        className={classNames(
          "flex cursor-pointer items-center gap-2",
          props.linkClassName
        )}
      >
        <Avatar
          src={
            props.writer.photo?.attributes?.unique_name
              ? resizeImage(props.writer.photo.attributes.unique_name, "50")
              : props.writer.photo.url
          }
          size={props.avatarSize || 32}
        />
        <div className="flex flex-col justify-start">
          {props.showName && (
            <p className="leading-6 text-white">{model.name}</p>
          )}
          <p
            className={classNames(
              "text-sm leading-6 text-memod-label",
              props.textClassName
            )}
          >
            {model.username}
          </p>
        </div>
      </Link>
    </div>
  );
}
