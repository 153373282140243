import ToolTipHover from '@components/molecules/tooltip-hover';
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames';
import { Fragment } from 'react';

export interface OptionInterface {
  text?: string;
  icon?: JSX.Element;
  title?: string;
  className?: string;
  onClick?: (event?: any) => void;
}

interface Props {
  className?: string;
  optionClassName?: string;
  iconButton?: JSX.Element;
  baseText?: string;
  options?: OptionInterface[];
  index?: string;
  onClick?: () => void;
  type?: "small" | "rounded";
  container?: "small" | "wide";
  position?: "left" | "right" | "center";
  CloseButton?: JSX.Element;
}

export default function DownDrop(props: Props) {
  const { type = "small", position = "right" } = props;

  const panel = classNames("items-center !text-base-white flex flex-row justify-center gap-2", {
    "bg-transparent": type === "small",
    "w-11 h-11 rounded-full bg-[#2C2C2E]": type === "rounded",
  });

  const positions = classNames("right-[190px]", {
    "left-[190px]": position === "left",
    "-left-[108px]": position === "center",
  })

  return (
    <div className="top-16 text-right">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className={panel}>
          <span>
            {props.iconButton}
          </span>
        </Menu.Button>
        <div>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={classNames(`absolute z-10 ${positions} mt-1 w-full divide-y divide-gray-100 rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`)}>
            <ul className={classNames(
              "w-[217px] h-full flex flex-col p-0 rounded-lg bg-memod-button",
              props.className,
            ) }>
              {props.baseText && (
                <Menu.Item key={1}>
                  <li className={classNames(
                    "w-full h-10 text-base-white flex flex-row justify-between  items-start p-2.5 gap-5 ",
                    props.optionClassName
                  )}>
                    <h3 className="w-full border-b !border-b-memod-label pb-2.5">
                      {props.baseText}
                    </h3>
                  </li>
                </Menu.Item>
              )}
              {props.options?.map((option: OptionInterface, index: number)=> {
                return(
                  <Menu.Item key={index}>
                      <li
                        onClick={option.onClick}
                        title={option.title}
                        className={classNames(
                          "w-full h-10 text-base-white flex flex-row justify-between items-start p-2 gap-5 hover:bg-[#3A3A3C] rounded-lg cursor-pointer",
                          { "!text-memod-green-base": props.index === option.text },
                          props.optionClassName,
                          option.className,
                        )}
                      >
                        {option.text} {option.icon}
                      </li>
                  </Menu.Item>
                );
              })}
              {props.CloseButton && (
                <Menu.Item key="close">
                  {({ close }) => (
                    <li onClick={close} className={classNames(
                      "w-full h-10 text-base-white flex flex-row justify-between items-start p-2 gap-5 hover:bg-[#3A3A3C] rounded-lg cursor-pointer",
                      props.optionClassName
                    )}>
                      {props.CloseButton}
                    </li>
                  )}
                </Menu.Item>
              )}
            </ul>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
