import ReactLoading from "react-loading";

interface Props {
  size?: number;
  className?: string;
}

export default function Loading(props: Props) {
  const { size = 20, className = "" } = props;
  return (
    <ReactLoading
      className={className}
      color="#979797"
      height={size}
      width={size}
      type="spin"
    />
  );
}
